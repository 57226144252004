<template>
  <GoogleLogin :callback="callback" :prompt="false" />
  <vue-apple-login
                   color="black"
                   :border="true"
                   type="sign in"
                   width="200"
                   height="36"
                   :onSuccess="onSuccess"
  ></vue-apple-login>
</template>

<script setup>
const callback = (response) => {
  // This callback will be triggered when the user selects or login to
  // his Google account from the popup
  console.log("Handle the response", response.credential)

}
const onSuccess = (response) => {
  console.log(response)
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
