import { createApp } from 'vue'
import App from './App.vue'
import vue3GoogleLogin from 'vue3-google-login'
import VueAppleLogin from 'vue-apple-login';


const app = createApp(App)

app.use(vue3GoogleLogin, {
    clientId: '378914980931-qjusihir68iqpnjeeeramdla55mp5org.apps.googleusercontent.com',
    redirectUri: 'https://test-spring.asmtech.pl/',
    uxMode: 'redirect',
    scope: 'email, profile',
});

app.use(VueAppleLogin, {
    clientId: 'app.localhost-app-social-login',
    scope: 'name email',
    redirectURI: 'https://test-spring.asmtech.pl/',
    state: "name",
    usePopup: true,
});
app.mount('#app')
